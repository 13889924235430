import request from '@/utils/request'


export function getBookList(query) {
	//如果有,号，则搜索每个标题
	if (query.type && query.type.indexOf(',') != -1) {
		query.searchTitle = query.type
		delete query.type
	}
	return request({
		url: '/api/front/book/list',
		method: 'get',
		params: query
	})
}
// 分类
export function getType() {
	return request({
		url: '/api/front/book/category/list',
		method: 'get',
	})
}
// swiper
export function getSwiper() {
	return request({
		url: '/api/front/advert/list?type=1',
		method: 'get'
	})
}
// 排行榜
export function getRank(query) {
	return request({
		url: '/api/front/book/list',
		method: 'get',
		params: query
	})
}
// 书详情
export function getBook(id) {
	return request({
		url: '/api/front/book/detail/' + id,
		method: 'get'
	})
}
// 章节详情
export function getContent(query) {
	return request({
		url: '/api/front/book/content',
		method: 'get',
		params: query
	})
}
// 章节列表
export function getChapter(id) {
	return request({
		url: '/api/front/book/chapter/list?bookId=' + id,
		method: 'get'
	})
}

//登录
// 登录方法
export function login(username, password) {
	const data = {
		username,
		password,
	}
	return request({
		url: '/login',
		headers: {
			isToken: false
		},
		method: 'post',
		data: data
	})
}
//获取用户信息
export function getInfo(query) {
	return request({
		url: '/getInfo',
		method: 'get',
		params: query
	})
}


//
//   添加收藏
export function addCollect(id) {
	return request({
		url: '/book/collection?bookId=' + id,
		method: 'post',
	})
}

//  首页推荐

export function getHotBook(query) {
	return request({
		url: '/api/front/book/home/list',
		method: 'get',
		params: query
	})
}
// 查询是否收藏
export function getCollect(id) {
	return request({
		url: '/book/isCollection?bookId=' + id,
		method: 'get'
	})
}

// 我的足迹
export function getStep() {
	return request({
		url: '/footPrint/list',
		method: 'get'
	})
}

//  /userCollection/list

export function userCollect() {
	return request({
		url: '/userCollection/list',
		method: 'get'
	})
}
// 统计
export function getData() {
	return request({
		url: '/api/front/book/statistics',
		method: 'get'
	})
}

// video
export function getVideoList(query) {
	return request({
		url: '/api/front/video/list',
		params: query,
		method: 'get'
	})
}
// video详情
export function getVideoDetail(id) {
	return request({
		url: '/api/front/video/'+id,
		method: 'get'
	})
}
